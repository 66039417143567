<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="40%">
      <v-card>
        <v-card-title> {{ $t("cartPage.checkOutReport") }}</v-card-title>
        <v-card-text class="pt-4" style="font-size: 1.3rem">
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field :rules="rules" fill outlined :label="$t('cartPage.projectName')"
                  v-model="info.project_name"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea :rules="rules" fill outlined :label="$t('cartPage.comment')" v-model="info.comment"
                  counter="500"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" text @click="$emit('close')" style="font-size: 1.1rem; letter-spacing: 0px">
            {{ $t("download.close") }}
          </v-btn>
          <v-btn text @click="handleClick" :loading="loading" style="font-size: 1.1rem; letter-spacing: 0px">
            {{ $t("download.okay") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog", "loading"],
  data() {
    return {
      info: {},
      rules: [(v) => !!v || this.$t("cartPage.required")],
    };
  },
  methods: {
    handleClick() {
      const isValid = this.$refs.form.validate();
      if (isValid) this.$emit("done", this.info);
    },
  },
};
</script>
