<template>
  <div>
    <h1 class="pb-2">{{ $t("cartPage.title") }}</h1>
    <v-divider></v-divider>
    <div v-if="!itemsLoading && items.length > 0">
      <item-card :cartItems="cartItems" @removedItem="getItems" @refresh="getItems" />

      <div class="text-center pt-3">
        <h1>
          {{ $t("cartPage.total") }}:
          <strong>{{ price }} {{ $t("cartPage.currency") }}</strong>
        </h1>
        <v-btn dark class="mt-3" style="letter-spacing: 0px" elevation="0" color="primary"
          @click="showDownloadDialog = true" :loading="downloadLoading">
          {{ $t("cartPage.checkOutReport") }}
          <v-icon class="mx-2">mdi-tray-arrow-down </v-icon></v-btn>
      </div>
    </div>

    <!-- Checkout report -->
    <VueHtml2pdf filename="Majaal checkout report" ref="html2Pdf" style="display: none" pdf-orientation="portrait"
      :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true" :pdf-quality="2"
      :manual-pagination="true" pdf-format="a4" pdf-content-width="800px">
      <section slot="pdf-content">
        <report :items="cartItems" :total="price" :reportData="reportData" :extraInfo="extraInfo" />
      </section>
    </VueHtml2pdf>

    <!-- Loader -->
    <div v-if="itemsLoading" class="mt-16 pt-16">
      <v-row><v-progress-circular indeterminate class="mx-auto" size="100"></v-progress-circular></v-row>
    </div>

    <!-- No content -->
    <no-content v-if="!itemsLoading && cartItems.length == 0" :title="$t('noContent.title')" :msg="$t('noContent.cart')"
      icon="mdi-robot-confused" />

    <completion-dialog :dialog="showCompletionDialog" @close="$router.push('/items')" />
    <download-dialog :dialog="showDownloadDialog" @close="showDownloadDialog = false" :loading.sync="downloadLoading" @done="quotations" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import CompletionDialog from "./Components/CompletionDialog";
import DownloadDialog from "./Components/DownloadDialog";

export default {
  name: "Cart",
  components: {
    ItemCard: () => import("./Components/ItemCard.vue"),
    NoContent: () => import("../../components/NoContent.vue"),
    Report: () => import("./Components/Report.vue"),
    VueHtml2pdf,
    CompletionDialog,
    DownloadDialog,
  },
  data() {
    return {
      itemsLoading: false,
      cartItems: [],
      showCompletionDialog: false,
      showDownloadDialog: false,
      downloadLoading: false,
      extraInfo: {},
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.cartModule.items,
      meta: (state) => state.cartModule.meta,
      reportData: (state) => state.cartModule.report,
    }),
    price() {
      var total = 0;
      for (let index = 0; index < this.cartItems.length; index++) {
        total += this.cartItems[index].baseItem.price * this.cartItems[index].quantity;
      }
      return total;
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.itemsLoading = true;
      this.$store
        .dispatch("cartModule/getItems", {})
        .then(() => {
          var items = this.items.map((e) => {
            if (e.carts[0].quantity > 1) {
              return {
                itemId: e.id,
                id: e.carts[0].id,
                baseItem: e.baseItem,
                category: e.category,
                images: e.images,
                uom: e.uom,
                quantity: e.carts[0].quantity,
              };
            } else {
              return {
                itemId: e.id,
                id: e.carts[0].id,
                baseItem: e.baseItem,
                category: e.category,
                images: e.images,
                uom: e.uom,
                quantity: 1,
              };
            }
          });
          // Put the array into storage
          localStorage.setItem("cartItems", JSON.stringify(items));

          // Retrieve the array from storage
          this.cartItems = JSON.parse(localStorage.getItem("cartItems"));
        })
        .finally(() => {
          this.itemsLoading = false;
        });
    },

    quotations(data) {
      this.downloadLoading = true;
      this.showDownloadDialog = false;
      this.extraInfo = data;
      this.$store
        .dispatch("cartModule/quotations", { body: data })
        .then(() => {
          this.generateReport();
        })
        .finally(() => {
          this.getCount();
          this.getItems();
          this.downloadLoading = false;
        });
    },
    getCount() {
      this.$store.dispatch("cartModule/getCount", {});
    },
    generateReport() {
      this.showCompletionDialog = true;
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
